import { NgModule } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { config, core as core$1 } from 'breeze-client';
import { last, map } from 'rxjs/operators';

/**
 * Minimum for breeze breeze Q/ES6 Promise adapter
 */
var Q = {
    defer: function () {
        var resolve;
        var reject;
        var promise = new Promise(function (_resolve, _reject) {
            resolve = _resolve;
            reject = _reject;
        });
        return {
            promise: promise,
            resolve: function (value) { resolve(value); },
            reject: function (reason) { reject(reason); }
        };
    },
    resolve: function (value) {
        var deferred = Q.defer();
        deferred.resolve(value);
        return deferred.promise;
    },
    reject: function (reason) {
        var deferred = Q.defer();
        deferred.reject(reason);
        return deferred.promise;
    }
};

var AjaxHttpClientAdapter = (function () {
    /**
     * @param {?} http
     */
    function AjaxHttpClientAdapter(http$$1) {
        this.http = http$$1;
        this.name = AjaxHttpClientAdapter.adapterName;
        this.defaultSettings = {};
    }
    /**
     * @return {?}
     */
    AjaxHttpClientAdapter.prototype.initialize = function () { };
    /**
     * @param {?} config
     * @return {?}
     */
    AjaxHttpClientAdapter.prototype.ajax = function (config$$1) {
        if (!this.http) {
            throw new Error('Unable to locate angular http module for ajax adapter');
        }
        // merge default DataSetAdapter Settings with config arg
        if (!core$1.isEmpty(this.defaultSettings)) {
            var /** @type {?} */ compositeConfig = core$1.extend({}, this.defaultSettings);
            config$$1 = (core$1.extend(compositeConfig, config$$1));
            // extend is shallow; extend headers separately
            var /** @type {?} */ headers_1 = core$1.extend({}, this.defaultSettings['headers']); // copy default headers 1st
            config$$1['headers'] = core$1.extend(headers_1, config$$1.headers);
        }
        if (config$$1.crossDomain) {
            throw new Error(this.name + ' does not support JSONP (jQuery.ajax:crossDomain) requests');
        }
        var /** @type {?} */ url = config$$1.url;
        if (!core$1.isEmpty(config$$1.params)) {
            // Hack: Not sure how Angular handles writing 'search' parameters to the url.
            // so this approach takes over the url param writing completely.
            var /** @type {?} */ delim = (url.indexOf('?') >= 0) ? '&' : '?';
            url = url + delim + encodeParams(config$$1.params);
        }
        var /** @type {?} */ headers = new HttpHeaders(config$$1.headers || {});
        if (!headers.has('Content-Type')) {
            if (config$$1.type != 'GET' && config$$1.type != 'DELETE' && config$$1.contentType !== false) {
                headers = headers.set('Content-Type', /** @type {?} */ (config$$1.contentType) || 'application/json; charset=utf-8');
            }
        }
        var /** @type {?} */ body = config$$1.data;
        var /** @type {?} */ request = new HttpRequest((config$$1.type || 'GET').toUpperCase(), url, body, { headers: headers, responseType: "text" });
        var /** @type {?} */ requestInfo = {
            adapter: this,
            request: request,
            dsaConfig: config$$1,
            success: successFn,
            error: errorFn // adapter's error callback
        };
        if (core$1.isFunction(this.requestInterceptor)) {
            this.requestInterceptor(requestInfo);
            if (this.requestInterceptor['oneTime']) {
                this.requestInterceptor = null;
            }
        }
        if (requestInfo.request) {
            this.http.request(requestInfo.request).pipe(last(), map(extractData)).subscribe(requestInfo.success, requestInfo.error);
        }
        /**
         * @param {?} response
         * @return {?}
         */
        function extractData(response) {
            var /** @type {?} */ data;
            var /** @type {?} */ dt = requestInfo.dsaConfig.dataType;
            // beware:`res.json` and `res.text` will be async some day
            if (dt && dt !== 'json') {
                data = response.body;
            }
            else {
                data = JSON.parse(response.body);
            }
            return { data: data, response: response };
        }
        /**
         * @param {?} arg
         * @return {?}
         */
        function successFn(arg) {
            if (arg.response.status < 200 || arg.response.status >= 300) {
                throw { data: arg.data, response: arg.response };
            }
            var /** @type {?} */ httpResponse = {
                config: requestInfo.request,
                data: arg.data,
                getHeaders: makeGetHeaders(arg.response.headers),
                status: arg.response.status
            };
            httpResponse['ngConfig'] = requestInfo.request;
            httpResponse['statusText'] = arg.response.statusText;
            httpResponse['response'] = arg.response;
            config$$1.success(httpResponse);
        }
        /**
         * @param {?} response
         * @return {?}
         */
        function errorFn(response) {
            if (response instanceof Error) {
                throw response; // program error; nothing we can do
            }
            else {
                var /** @type {?} */ data;
                if (response.error instanceof HttpResponse) {
                    data = response.error.body;
                }
                else if (response.error instanceof Error) {
                    data = response.error.message;
                }
                else {
                    data = response.error;
                }
                // Timeout appears as an error with status===0 and no data.
                if (response.status === 0 && data == null) {
                    data = 'timeout';
                }
                var /** @type {?} */ errorMessage = response.status + ": " + response.statusText;
                if (data && typeof data === 'object') {
                    data["message"] = data["message"] || errorMessage; // breeze looks at the message property
                }
                if (!data) {
                    data = errorMessage; // Return the error message as data
                }
                var /** @type {?} */ httpResponse = {
                    config: requestInfo.request,
                    data: data,
                    getHeaders: makeGetHeaders(response.headers),
                    status: response.status
                };
                httpResponse['ngConfig'] = requestInfo.request;
                httpResponse['statusText'] = response.statusText;
                httpResponse['response'] = response;
                config$$1.error(httpResponse); // send error to breeze error handler
            }
        }
    };
    
    return AjaxHttpClientAdapter;
}());
AjaxHttpClientAdapter.adapterName = 'angular';
/**
 * @param {?} obj
 * @return {?}
 */
function encodeParams(obj) {
    var /** @type {?} */ query = '';
    var /** @type {?} */ subValue, /** @type {?} */ innerObj, /** @type {?} */ fullSubName;
    for (var /** @type {?} */ name_1 in obj) {
        if (!obj.hasOwnProperty(name_1)) {
            continue;
        }
        var /** @type {?} */ value = obj[name_1];
        if (value instanceof Array) {
            for (var /** @type {?} */ i = 0; i < value.length; ++i) {
                subValue = value[i];
                fullSubName = name_1 + '[' + i + ']';
                innerObj = {};
                innerObj[fullSubName] = subValue;
                query += encodeParams(innerObj) + '&';
            }
        }
        else if (value && value.toISOString) {
            query += encodeURIComponent(name_1) + '=' + encodeURIComponent(value.toISOString()) + '&';
        }
        else if (value instanceof Object) {
            for (var /** @type {?} */ subName in value) {
                if (obj.hasOwnProperty(name_1)) {
                    subValue = value[subName];
                    fullSubName = name_1 + '[' + subName + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += encodeParams(innerObj) + '&';
                }
            }
        }
        else if (value === null) {
            query += encodeURIComponent(name_1) + '=&';
        }
        else if (value !== undefined) {
            query += encodeURIComponent(name_1) + '=' + encodeURIComponent(value) + '&';
        }
    }
    return query.length ? query.substr(0, query.length - 1) : query;
}
/**
 * @param {?} headers
 * @return {?}
 */
function makeGetHeaders(headers) {
    return function getHeaders(headerName) { return headers.getAll(headerName).join('\r\n'); };
}

var BreezeBridgeHttpClientModule = (function () {
    /**
     * @param {?} http
     */
    function BreezeBridgeHttpClientModule(http$$1) {
        this.http = http$$1;
        // Configure Breeze for Angular ... exactly once.
        // config breeze to use the native 'backingStore' modeling adapter appropriate for Ng
        // 'backingStore' is the Breeze default but we set it here to be explicit.
        config.initializeAdapterInstance('modelLibrary', 'backingStore', true);
        config.setQ(Q);
        config.registerAdapter('ajax', function () { return new AjaxHttpClientAdapter(http$$1); });
        config.initializeAdapterInstance('ajax', AjaxHttpClientAdapter.adapterName, true);
    }
    return BreezeBridgeHttpClientModule;
}());
BreezeBridgeHttpClientModule.decorators = [
    { type: NgModule },
];
/**
 * @nocollapse
 */
BreezeBridgeHttpClientModule.ctorParameters = function () { return [
    { type: HttpClient, },
]; };

export { Q, BreezeBridgeHttpClientModule, AjaxHttpClientAdapter };
